import React from 'react';
import Image from 'next/image';
import { useWindowWidth } from '@atlas/ui-library/hooks/useWindowWidth';
import type { LogoProps } from './types';
import { isMobile } from '@atlas/ui-library/lib/isMobile';

const Desktop = ({ image }: Pick<LogoProps, 'image'>) => {
	const { src, alt, title, width, height } = image;
	return (
		<Image
			unoptimized
			src={src}
			alt={alt}
			title={title}
			width={width ?? '250'}
			height={height ?? '40'}
			quality={100}
		/>
	);
};

const Mobile = ({ image, isScrolled }: LogoProps) => {
	const { src } = image;
	const { alt, title, height } = image;

	const replaceColorSuffix = (from: string, to: string) =>
		src.toString().replace(from, to);

	const getImageSrc = () =>
		isScrolled
			? replaceColorSuffix('.webp', '_inverted.webp')
			: replaceColorSuffix('_inverted.webp', '.webp');

	return (
		<Image
			unoptimized
			src={getImageSrc()}
			alt={alt}
			title={title}
			width={'110'}
			height={height ?? '40'}
			quality={100}
		/>
	);
};

export const Logo = ({ image, isScrolled }: LogoProps) => {
	const windowWidth = useWindowWidth();
	const isMobileView = isMobile(windowWidth);

	const replaceSizeSuffix = (from: string, to: string) =>
		image.src.toString().replace(from, to);

	const getImageSrc = () =>
		isMobileView
			? replaceSizeSuffix('lg', 'xs')
			: replaceSizeSuffix('xs', 'lg');

	return isMobileView ? (
		<Mobile
			image={{
				...image,
				src: getImageSrc(),
			}}
			isScrolled={isScrolled}
		/>
	) : (
		<Desktop image={image} />
	);
};
